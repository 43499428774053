import axios from 'axios';

// var addr = 'http://localhost:7979/api'
var addr = 'https://sanyuanapi.deaso40.com/api';

const login = async function(url, data){
  const result = await axios.post(
    addr + url, 
    data
  );
  return result.data;
}

const post = async function(url, data){
  const userinfo = localStorage.getItem("sanyuan_admin_userInfo");
  if(!userinfo){
    // _this.$message.error('请先登录！');
    throw('未登录')
  } 
  
  const result = await axios.post(
    addr + url, 
    data,
    {
      headers: {
        'token': localStorage.getItem("sanyuan_admin_userInfo")
      }
    }
  );
  return result.data;
}

const postWithoutHead = async function(url, data){
  const result = await axios.post(
    addr + url, 
    data
  );
  return result.data;
}

export default {
  post,
  login,
  postWithoutHead
}